import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { PageSection, PageTextRectangle, PageBanner } from '../components'
import { StaticImage } from 'gatsby-plugin-image'
import { projektaiPage } from '../assets/texts/projektai-page'

const MusuVeiklosIstorijos = () => (
  <Layout>
    <Helmet>
      <title>Krasto pazinimas - musu veiklos istorijos</title>
      <meta name="description" content="musu-veiklos-istorijos" />
    </Helmet>
    
  <PageBanner title='Mūsų veiklos istorijos'>
    <StaticImage
      className="page-banner-image"
      src="../assets/images/projektai_banner.jpg"
      alt="projektai"
      layout="fullWidth"
    />
  </PageBanner>
  
  {projektaiPage.map(({ paragraph, title}, index) => (
    // <PageSection key={`${index}-${title}`} className="section-4 content-page">
    <PageSection key={`${index}-${title}`} className="content-page">
      <PageTextRectangle
        className='white-text-background'
        title={title}
        text={paragraph}
      />
    </PageSection>
  ))}
  
  <PageSection className="section-4 content-page">
  {/*<PageSection className="content-page">*/}
    <div className="sponsors-logo">
      <StaticImage
        // className="page-banner-image"
        src="../assets/images/ltk_logo.png"
        alt="ltk_logo"
        width={300}
        // layout="fullWidth"
      />
      
      <StaticImage
        // className="page-banner-image"
        src="../assets/images/simono_daukanto_gimnazija_logo.jpg"
        alt="simono_daukanto_gimnazija_logo"
        width={100}
        // layout="fullWidth"
      />
  
      <StaticImage
        // className="page-banner-image"
        src="../assets/images/akmenes_rajono_savivaldybes_logo.png"
        alt="akmenes_rajono_savivaldybes_logo"
        width={300}
    
        // layout="fullWidth"
      />
    </div>
  </PageSection>
</Layout>
)

export default MusuVeiklosIstorijos
