export const projektaiPage = [
  {
    title: '2021 m.vykdyti projektai:',
    paragraph: 'Lietuvos kultūros tarybos dalinai finansuotas projektas "Lietuvai reikšmingo kultūros paveldo esančio Latvijos Respublikoje lankymas"'
  },
  {
    title: undefined,
    paragraph: 'Akmenės rajono savivaldybės finansuojamas projektas "Eksperimentinė archeologija"'
  },
  {
    title: '2020 m. vykdyti projektai: ',
    paragraph: 'Akmenės rajono savivaldybės finansuojamas projektas „Vidurio ir Rytų Lietuvos istorinio kultūrinio palikimo pėdomis“'
  },
  {
    title: undefined,
    paragraph: 'Akmenės rajono savivaldybės finansuojamas projektas „Aš galiu būti archeologu!“'
  },
  {
    title: 'Projektus finansuoja:',
    paragraph: ''
  }
]
